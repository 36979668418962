<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top">
    <div class="container">
      <a class="navbar-brand" href="/">Sulek</a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <router-link to="/" v-slot="{ href, navigate, isExactActive }" custom>
            <li class="nav-item" :class="[isExactActive && 'active']">
              <a class="nav-link" :href="href" @click="navigate"><font-awesome-icon icon="home" /> Home</a>
            </li>
          </router-link>
          <router-link to="/api" v-slot="{ href, navigate, isExactActive }" custom>
            <li class="nav-item" :class="[isExactActive && 'active']">
              <a class="nav-link" :href="href" @click="navigate"><font-awesome-icon icon="code" /> Api</a>
            </li>
          </router-link>
          <router-link to="/about" v-slot="{ href, navigate, isExactActive }" custom>
            <li class="nav-item" :class="[isExactActive && 'active']">
              <a class="nav-link" :href="href" @click="navigate"><font-awesome-icon icon="address-card" /> About</a>
            </li>
          </router-link>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navbar'
}
</script>
