<template>
  <div>
    <h1>Releases</h1>
    <div class="row">
      <div class="col-md-12 mb-3">
        <nav class="nav nav-pills nav-justified">
          <a class="nav-item nav-link" :class="{active: isVariantAir}" href="#flash-windows">
            Habbo Air
          </a>
          <a class="nav-item nav-link" :class="{active: isVariantShockwave}" href="#shockwave-windows">
            Habbo Origins
          </a>
          <a class="nav-item nav-link" :class="{active: isVariantWeb}" href="#flash">
            Habbo Web
          </a>
        </nav>
      </div>
    </div>

    <div class="row" v-if="isVariantAir">
      <div class="col-md-12">
        <nav class="nav nav-tabs nav-justified">
          <a class="nav-item nav-link" :class="{active: variant === 'flash-windows'}" href="#flash-windows">
            <font-awesome-icon :icon="['fab', 'windows']"/> Windows
          </a>
          <a class="nav-item nav-link" :class="{active: variant === 'flash-osx'}" href="#flash-osx">
            <font-awesome-icon :icon="['fab', 'apple']"/> OSX
          </a>
          <a class="nav-item nav-link" :class="{active: variant === 'mobile'}" href="#mobile">
            <font-awesome-icon icon="mobile-alt"/> Mobile
          </a>
        </nav>
      </div>
    </div>

    <div class="row" v-if="isVariantShockwave">
      <div class="col-md-12">
        <nav class="nav nav-tabs nav-justified">
          <a class="nav-item nav-link" :class="{active: variant === 'shockwave-windows'}" href="#shockwave-windows">
            <font-awesome-icon :icon="['fab', 'windows']"/> Windows
          </a>
          <a class="nav-item nav-link" :class="{active: variant === 'shockwave-osx'}" href="#shockwave-osx">
            <font-awesome-icon :icon="['fab', 'apple']"/> OSX
          </a>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
            <tr>
              <th>Release</th>
              <th style="width: 100px;">Size</th>
              <th style="width: 20%;">Packets</th>
              <th style="width: 15%;"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="releasesLoading">
              <td class="align-middle text-center" colspan="4">
                <font-awesome-icon icon="spinner" spin /> Loading releases
              </td>
            </tr>
            <tr v-else-if="releases.length > 0" v-for="release in releases" :key="release.id">
              <td class="align-middle">{{ release.version }}</td>
              <td class="align-middle">{{ release.size | prettyBytes }}</td>
              <td class="align-middle">
                <div class="progress" v-if="release.packets.total != 0">
                  <div class="progress-bar" role="progressbar"
                       :style="{width: progress(release.packets.count, release.packets.total) + '%'}"
                       :class="{'bg-success': (release.packets.count === release.packets.total),
                              'bg-warning': (release.packets.count !== release.packets.total)}">{{ progress(release.packets.count, release.packets.total) }}%
                  </div>
                </div>
                <div class="progress" v-else>
                  <div class="progress-bar bg-danger" role="progressbar" style="width: 100%">No packets
                  </div>
                </div>
              </td>
              <td>
                <router-link :to="{ name: 'Release', params: { variant: release.variantPath, version: release.version }}" type="button" class="btn btn-primary btn-block">Info</router-link>
              </td>
            </tr>
            <tr v-else>
              <td class="table-secondary" colspan="4">No releases found</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: 'Home',
  data: function () {
    return {
      variantsAir: ['flash-windows', 'flash-osx', 'mobile'],
      variantsShockwave: ['shockwave-windows', 'shockwave-osx'],
      variantsWeb: ['flash'],
      variant: 'flash-windows',
      releases: [],
      releasesLoading: true
    }
  },
  computed: {
    isVariantAir() {
      return this.variantsAir.includes(this.variant)
    },
    isVariantShockwave() {
      return this.variantsShockwave.includes(this.variant)
    },
    isVariantWeb() {
      return this.variantsWeb.includes(this.variant)
    },
  },
  methods: {
    progress(value, max) {
      return (100 / max * value).toFixed(2)
    },
    updateVariant(hash) {
      if (hash === '#flash' ||
        hash === '#flash-windows' ||
        hash === '#flash-osx' ||
        hash === '#shockwave-windows' ||
        hash === '#shockwave-osx' ||
        hash === '#mobile'
      ) {
        let variant = hash.substring(1);

        if (this.variant !== variant) {
          this.variant = variant
        }
      }

      this.getReleases()
    },
    getReleases() {
      this.releasesLoading = true

      axios.get(process.env.VUE_APP_API_URL + '/releases', { params: { variant: this.variant } }).then(response => {
        this.releases = response.data
        this.releasesLoading = false
      })
    }
  },
  watch: {
    '$route': function (to) {
      this.updateVariant(to.hash)
    }
  },
  mounted() {
    this.updateVariant(this.$route.hash)
  }
}
</script>

<style lang="scss">
.progress {
  height: 35px !important;
}
</style>
