import Vue from 'vue'
import App from './App.vue'
import router from './router'

// Bootstrap.
import 'bootstrap/dist/js/bootstrap.min'
import 'bootswatch/dist/cyborg/bootstrap.min.css'
import './assets/style.scss'

// Font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faCloud, faAddressCard, faSpinner, faGlobe, faArrowCircleUp, faArrowCircleDown, faCode, faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { faApple, faWindows, faGithub, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome)
library.add(faCloud)
library.add(faAddressCard)
library.add(faSpinner)
library.add(faGlobe)
library.add(faArrowCircleUp)
library.add(faArrowCircleDown)
library.add(faCode)
library.add(faMobileAlt)
library.add(faApple)
library.add(faWindows)
library.add(faGithub)
library.add(faDiscord)

// Vue
Vue.config.productionTip = false

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.filter('prettyBytes', function (num) {
  // jacked from: https://github.com/sindresorhus/pretty-bytes
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
